<template>
  <b-row>
      <b-col lg="6">
        <MainForm
          type="edit"
          v-on:submit="submit" />
      </b-col>
  </b-row>
</template>
<script>
import MainForm from './form'
// import { mapState } from 'vuex'

export default {
  name: 'permissionEdit',
  data() {
    return {}
  },
  computed: {},
  created() {
    const vm = this
    vm.$store.dispatch('permission/EDIT', vm.$route.params.id)
  },
  methods: {
    submit() {
      const vm = this
      vm.$store.dispatch('permission/UPDATE', vm.$route.params.id).then((res) => {
        vm.$notification.success({
          message: 'Permission Updated',
          description: 'Successfully updated.',
        })
        vm.$router.push({ path: '/permissions' })
      })
    },
  },
  components: {
    MainForm,
  },
}
</script>
