<template>
  <div>
    <a-form-model :colon="false" @submit.prevent="$emit('submit')" spellcheck="false" ref="form" :model="form" :rules="rules">
      <b-row>
        <b-col cols="12">
          <a-form-model-item
            label="Name"
            name="name"
            prop="name">
            <a-input :disabled="loading" required v-model="form.name" />
          </a-form-model-item>
          <a-form-model-item
            label="Description"
            name="description"
            prop="description">
            <a-textarea :disabled="loading" required v-model="form.description" />
          </a-form-model-item>
          <div class="text-right">
            <b-button :disabled="loading" variant="primary" @click="submit()">
              {{ type === 'create' ? 'Add New Permission' : 'Save Changes' }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </a-form-model>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'mainForm',
  props: {
    type: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      rules: {
        name: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        description: [
          {
            required: true,
            message: 'Please input description',
          },
        ],
      },
    }
  },
  created() {
    const vm = this
    vm.$store.dispatch('permission/FETCH', {
      perPage: 200,
    })
  },
  computed: {
    ...mapState(['settings']),
    ...mapState('permission', {
      permissions: state => state.data,
      form: state => state.form,
    }),
    loading() {
      return this.$store.state.permission.loading
    },
    permissionDataLoading() {
      return this.$store.state.permission.loading
    },
  },
  methods: {
    submit() {
      const vm = this

      vm.$refs.form.validate(valid => {
        if (valid) {
          vm.$emit('submit')
        } else {
          return false
        }
      })
    },
    resetForm() {
      this.$refs.form.resetFields()
    },
  },
  watch: {},
  components: {},
  destroyed() {
    this.$store.commit('permission/CLEAR_DATA')
    this.$store.commit('permission/CLEAR_FORM')
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
